








































































































































































































import { Vue, Component } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import AreaSelect from '@/components/area-select.vue'
import { apiSettingShopEdit, apiSettingShopInfo } from '@/api/setting/shop'
import { ShopEdit_Req } from '@/api/setting/shop.d.ts'

@Component({
  components: {
    AreaSelect,
    MaterialSelect,
  },
})
export default class SettingShop extends Vue {
  /** S Data **/
  // 表单数据
  form: ShopEdit_Req = {
    name: '', // 平台名称
    logo: '', // 平台logo
    admin_login_image: '', // 管理后台登录页图片
    login_restrictions: 0, // 管理后台登录限制: 0-不限制 1-限制
    password_error_times: 0, // 限制密码错误次数
    limit_login_time: 0, // 限制禁止多少分钟不能登录
    status: 0, // 平台状态: 0-关闭 1-开启
    mall_contact: '', // 联系人
    mall_contact_mobile: '', // 联系人手机号
    return_contact: '', // 退货联系人
    return_contact_mobile: '', // 退货联系人手机号
    return_province: 0, // 退货省份id
    return_city: 0, // 退货城市id
    return_district: 0, // 退货区域id
    return_address: '', // 退货详细地址
  }

  // 图片
  images = {
    storeLogin: require('@/assets/images/setting/img_shili_store_login.png'),
    storeClose: require('@/assets/images/setting/img_shili_store_close.png'),
    adminLogin: require('@/assets/images/setting/img_shili_admin_login.png'),
  }

  // 手机表单验证
  ruleMobile: Array<object> = [
    { required: true, message: '必填项不能为空', trigger: 'blur' },
    {
      pattern: /^1[3|4|5|7|8]\d{9}$/,
      message: '请输入合法手机号',
      trigger: 'blur',
    },
  ]

  // 地区表单验证方法
  validatorArea: Function = (
    rule: object,
    value: string,
    callback: Function
  ) => {
    if (this.form.return_province) callback()
    else callback(new Error())
  }

  // 表单验证
  rules: object = {
    name: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    logo: [{ required: true, message: '必填项不能为空', trigger: 'blur' }],
    admin_login_image: [
      { required: true, message: '必填项不能为空', trigger: 'blur' },
    ],
    mall_contact: [
      { required: true, message: '必填项不能为空', trigger: 'blur' },
    ],
    return_contact: [
      { required: true, message: '必填项不能为空', trigger: 'blur' },
    ],
    mall_contact_mobile: this.ruleMobile,
    return_contact_mobile: this.ruleMobile,
    return_district: [
      {
        required: true,
        message: '必填项不能为空',
        validator: this.validatorArea,
        trigger: 'blur',
      },
    ],
    return_address: [
      { required: true, message: '必填项不能为空', trigger: 'blur' },
    ],
  }
  /** E Data **/

  /** S Methods **/
  // 初始化表单数据
  initFormData() {
    apiSettingShopInfo()
      .then((res) => {
        // 表单同步于接口响应数据
        for (const key in res) {
          if (!this.form.hasOwnProperty(key)) continue
          this.form[key] = res[key]
        }
      })
      .catch(() => {
        this.$message.error('数据加载失败，请刷新重载')
      })
  }

  // 重置表单数据
  onResetFrom() {
    this.initFormData()
    this.$message.info('已重置数据')
  }

  // 提交表单
  onSubmitFrom(formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement
    console.log(refs.validate((valid: boolean): boolean => valid))

    refs.validate((valid: boolean) => {
      if (!valid) return this.$message.error('请完善信息')
      const loading = this.$loading({ text: '保存中' })
      const params = { ...this.form }

      // 根据需求删除冗余参数
      if (!params.login_restrictions) {
        delete params.limit_login_time
        delete params.password_error_times
      }

      apiSettingShopEdit({
        ...params,
      })
        .then(() => {
          this.$store.dispatch('getConfig')
          this.$message.success('保存成功')
        })
        .catch(() => {
          this.$message.error('保存失败')
        })
        .finally(() => {
          loading.close()
        })
    })
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
    this.initFormData()
  }
  /** E Life Cycle **/
}
